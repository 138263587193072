<template>
  <Menu
    ref="menuElement"
    v-slot="{ open, close }"
    as="div"
    class="menu-container"
    :class="containerClass"
  >
    <MenuButton
      as="template"
      :id="items.map(item => item.text).join('-') + '-menu-button'"
      :data-is-open="setIsOpen(open)"
      :aria-label="$t('common.menu')"
      @click.prevent="open && close()"
      @mouseenter="
        () => {
          showOnHover ? openMenu(open) : null;
          mouseIsOver = 'button';
        }
      "
      @mouseleave="
        () => {
          mouseIsOver = mouseIsOver === 'button' ? '' : mouseIsOver;
        }
      "
    >
      <slot :open="open" :close="close"></slot>
    </MenuButton>
    <Transition
      enter-active-class="transition duration-[0.21] ease-in-out"
      enter-from-class="transform scale-90 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-[0.1] ease-in-out"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-90 opacity-0"
    >
      <MenuItems
        as="ul"
        :class="['dropdown-menu absolute', attrClass]"
        :style="attrStyle"
        @mouseenter="
          () => {
            mouseIsOver = 'menu';
          }
        "
        @mouseleave="
          () => {
            mouseIsOver = mouseIsOver === 'menu' ? '' : mouseIsOver;
          }
        "
      >
        <slot name="menu-header" :close="close"></slot>
        <template v-for="(item, index) in items">
          <div
            v-if="item.divider && item.text"
            :key="'divider-with-text' + index"
            class="w-full text-neutral-lighter uppercase font-bold text-xs px-4 py-2 -mb-3"
          >
            {{ item.text }}
          </div>
          <div
            v-else-if="item.divider"
            :key="'divider' + index"
            class="w-full h-px bg-line-lighter"
          ></div>
          <MenuItem
            v-else
            :key="item.text + index"
            v-slot="{ active }"
            :disabled="item.disabled"
          >
            <component
              :is="item.to ? (item.external ? 'a' : NuxtLink) : 'li'"
              :class="[
                'menu-item',
                active ? 'menu-itemActive' : 'menu-itemDefault'
              ]"
              v-bind="item.external ? { href: item.to } : { to: item.to }"
              @click="() => item.action?.(close)"
            >
              <slot name="menu-item" :item="item" :close="close">
                {{ item.text }}
              </slot>
            </component>
          </MenuItem>
        </template>
        <slot name="menu-header" :close="close"></slot>
      </MenuItems>
    </Transition>
  </Menu>
</template>

<script lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import type { PropType } from 'vue';

import { debounce } from '@/utils/time';

export default {
  inheritAttrs: false
};
</script>

<script setup lang="ts">
const { class: attrClass, style: attrStyle } = useAttrs();

type MenuItemInterface =
  | {
      divider?: false;
      text: string;
      action?: (close?: () => void) => void;
      to?: string;
      external?: boolean;
      disabled?: boolean;
    }
  | {
      divider: true;
      text?: string;
      action?: (close?: () => void) => void;
      to?: string;
      external?: boolean;
      disabled?: boolean;
    };

const NuxtLink = resolveComponent('NuxtLink');

type Emits = {
  (event: 'update:open', value: boolean): void;
};

const emit = defineEmits<Emits>();

defineProps({
  items: {
    type: Array as PropType<MenuItemInterface[]>,
    default: () => []
  },
  containerClass: {
    type: String,
    default: ''
  },
  showOnHover: {
    type: Boolean,
    default: false
  }
});

let _isOpen: boolean | null = null;

function setIsOpen(value: boolean) {
  if (_isOpen === value) {
    return value;
  }

  _isOpen = value;
  setTimeout(() => {
    emit('update:open', value);
  }, 0);
  return value;
}

const menuElement = ref<{ $el: HTMLElement } | null>(null);

const mouseIsOver = ref('');

function getIsOpen() {
  return menuElement.value?.$el?.dataset.headlessuiState === 'open';
}

function triggerClick() {
  if (menuElement.value?.$el) {
    menuElement.value?.$el.getElementsByTagName('button')[0].click();
  }
}

declare global {
  interface Window {
    closePreviousMenu: () => void;
  }
}

const closeMenu = () => {
  if (menuElement.value?.$el?.dataset.headlessuiState === 'open') {
    menuElement.value?.$el.getElementsByTagName('button')[0].click();
    setTimeout(() => {
      menuElement.value?.$el.getElementsByTagName('button')[0].blur();
    }, 0);
  }
};

const openMenu = (isOpen: boolean) => {
  isOpen = isOpen || getIsOpen();
  if (!isOpen) {
    if (window.closePreviousMenu && window.closePreviousMenu !== closeMenu) {
      window.closePreviousMenu();
    }
    window.closePreviousMenu = closeMenu;
    triggerClick();
  }
};

let closeTimeout = null;

function checkMouse() {
  if (mouseIsOver.value === '') {
    closeMenu();
  }
}

watch(
  () => mouseIsOver.value,
  () => {
    clearTimeout(closeTimeout);
    closeTimeout = setTimeout(checkMouse, 100);
  }
);
</script>
